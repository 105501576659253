(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/helper-get-game-url/assets/javascripts/get-game-url.js') >= 0) return;  svs.modules.push('/components/lb-utils/helper-get-game-url/assets/javascripts/get-game-url.js');
"use strict";


const _getAllServerImports = () => {
  const path = require('path');
  const trinidad = require('trinidad-core').core;
  const trinidadRequire = trinidad.components.require;
  const localRequire = file => require(path.join(__dirname, file));
  return Object.freeze({
    getLogger: trinidad.logger,
    dateFnsInstance: trinidadRequire('libs', 'date-fns').api,
    urlMapping: trinidad.urlMapping,
    products: trinidadRequire('utils', 'products'),
    racingNavigation: localRequire('../../../../racing/race-navigation/assets/javascripts/location.es6'),
    racingServiceParse: localRequire('../../../../racing/race-service/assets/javascripts/utils/parse.es6')
  });
};
const _getAllClientImports = svs => Object.freeze({
  getLogger: svs.core.log.getLogger,
  dateFnsInstance: dateFns,
  urlMapping: svs.core.urlMapping,
  products: svs.utils.products,
  racingNavigation: svs.racing.navigation.location,
  racingServiceParse: svs.racing.raceService.utils.parse
});
const {
  getLogger,
  dateFnsInstance,
  urlMapping,
  products: {
    productIds
  },
  racingNavigation,
  racingServiceParse
} = svs.isServer ? _getAllServerImports() : _getAllClientImports(svs);
const logger = getLogger('lb-utils:helper-get-game-url');
const ODDSET_SPORTSBOOK = 34;
const CASINO = 1001;
const BINGO_M = 16;
const BINGO_CLASSIC_SINGLE = 71;
const BINGO_CLASSIC = 72;
const BINGO_PATTERN_SINGLE = 73;
const BINGO_PATTERN = 74;
const BINGO_VARIANT_SINGLE = 75;
const BINGO_VARIANT = 76;
const POKER = 80;
const product2urlMapping = {
  [productIds.POWERPLAY]: 'powerplayHome',
  [productIds.T8]: 'topptipsetHome',
  [productIds.T8STRYK]: 'topptipsetHome',
  [productIds.T8EXTRA]: 'topptipsetHome',
  [productIds.EUROPATIPSET]: 'europatipsetHome',
  [productIds.STRYKTIPSET]: 'stryktipsetHome',
  [productIds.CHALLENGE]: 'challengeHome',
  [productIds.BOMBEN]: 'bombenHome',
  [productIds.MATCHEN]: 'matchenHome',
  [productIds.MALTIPSET]: 'maltipsetHome',
  [productIds.RACING]: 'racing',
  [productIds.FULLTRAFF]: 'fulltraffHome',
  [ODDSET_SPORTSBOOK]: 'oddsetSportsbookHome',
  [CASINO]: 'casinoHome',
  [BINGO_M]: 'bingoHome',
  [BINGO_CLASSIC_SINGLE]: 'bingoHome',
  [BINGO_CLASSIC]: 'bingoHome',
  [BINGO_PATTERN_SINGLE]: 'bingoHome',
  [BINGO_PATTERN]: 'bingoHome',
  [BINGO_VARIANT_SINGLE]: 'bingoHome',
  [BINGO_VARIANT]: 'bingoHome',
  [POKER]: 'pokerHome'
};

function getGameUrl(_ref) {
  let {
    productId,
    drawNumber = null,
    options = null
  } = _ref;
  const {
    subPath = '',
    search = ''
  } = options || {};
  const _product2urlMapping = product2urlMapping;
  const baseUrl = urlMapping.get(_product2urlMapping[productId]) || '/';
  const searchObj = new URLSearchParams(search);
  searchObj.set('product', productId);
  switch (productId) {
    case productIds.EUROPATIPSET:
    case productIds.STRYKTIPSET:
    case productIds.POWERPLAY:
    case productIds.FULLTRAFF:
    case productIds.BOMBEN:
      searchObj.delete('product');
    case productIds.T8:
    case productIds.T8STRYK:
    case productIds.T8EXTRA:
      if (drawNumber) {
        searchObj.set('draw', drawNumber);
      }
      return "".concat(baseUrl).concat(subPath, "?").concat(searchObj);
    case productIds.CHALLENGE:
    case productIds.MATCHEN:
      return "".concat(baseUrl).concat(drawNumber ? "/spela/".concat(drawNumber) : '').concat(subPath).concat(search ? "?".concat(search) : '');
    case productIds.MALTIPSET:
      return "".concat(baseUrl).concat(drawNumber ? "/".concat(drawNumber) : '').concat(subPath).concat(search ? "?".concat(search) : '');
    case productIds.RACING:
      if (drawNumber) {
        return "".concat(baseUrl).concat(subPath).concat(drawNumber ? "/draw/".concat(drawNumber) : '').concat(search ? "?".concat(search) : '');
      }
      return "".concat(baseUrl === '/' && subPath ? '' : baseUrl).concat(subPath).concat(search ? "?".concat(search) : '');
    default:
      return "".concat(baseUrl === '/' && subPath ? '' : baseUrl).concat(subPath).concat(search ? "?".concat(search) : '');
  }
}

function getRacingGameUrl(_ref2) {
  var _meeting$track;
  let {
    race,
    userProduct = null
  } = _ref2;
  const {
    raceNumber,
    meeting
  } = race || {};
  if (!raceNumber || !(meeting !== null && meeting !== void 0 && meeting.date) || !(meeting !== null && meeting !== void 0 && (_meeting$track = meeting.track) !== null && _meeting$track !== void 0 && _meeting$track.pathName)) {
    logger.info('Error in getRacingGameUrl(): Missing race-data');
    return '';
  }
  const {
    format,
    parseISO
  } = dateFnsInstance;
  const {
    locationToUrl,
    buildCouponLocation
  } = racingNavigation;
  const {
    parseGameType
  } = racingServiceParse;
  const baseUrl = urlMapping.get('racing') || '';
  const day = format(parseISO(meeting.date), 'yyyy-MM-dd');
  const trackName = meeting.track.pathName;
  const gameType = userProduct ? parseGameType({
    userProduct
  }) : undefined;
  return baseUrl + locationToUrl(buildCouponLocation({
    day,
    trackName,
    raceNumber,
    gameType
  }));
}

if (svs.isServer) {
  module.exports = {
    getGameUrl,
    getRacingGameUrl
  };
} else {
  setGlobal('svs.components.lbUtils', {
    getGameUrl,
    getRacingGameUrl
  });
}

 })(window);